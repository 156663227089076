<template>
  <div id="search" class="search-container container" :style="{ backgroundImage: 'url(' + require('@/assets/background.png') + ')' }">
    <div class="flow-container w-1200">
      <div class="flow-body">
        <div class="flow-cell flow-bs">
          <BodySites ref="bsc" title="Body Sites" v-on:added="addToFilter('bodysites', $event)" v-on:removed="removeFromFilter('bodysites', $event)"/>
        </div>
				<div class="flow-cell flow-cat">
          <Categories ref="cgc" title="Categories" v-on:added="addToFilter('categories', $event)" v-on:removed="removeFromFilter('categories', $event)"/>
          <Instruments ref="isc" title="Instruments" v-on:added="addToFilter('instruments', $event)" v-on:removed="removeFromFilter('instruments', $event)"/>
          <HMP ref="hmc" title="HMP" v-on:added="addToFilter('hmp', $event)" v-on:removed="removeFromFilter('hmp', $event)"/>
        </div>
				<div class="flow-cell flow-data">
          <h3>Data Distribution</h3><br/>
          <div class="select">
            <select v-model="chartAxis" @change="updateDimensions()">
            <option id="bodysite" selected>bodysite</option>
            <option id="categories">category</option>
            <option id="instrument">instrument</option>
            <option id="hmp">hmp</option>
            </select>
          </div>
          <div id="chart" class="chart-container">
            <apexchart type="pie" width="800" :options="chartOptions" :series="series"></apexchart>
          </div>
        </div>
				<div class="flow-cell flow-download">
          <h3>Download</h3>
          <button type="button" v-on:click="downloadResults">Download OTU abundance and metadata file</button>
          <button type="button" v-on:click="downloadTaxas">Download taxonomic file</button>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import BodySites from './BodySites.vue'
import Categories from './Categories.vue'
import Instruments from './Instruments.vue'
import HMP from './HMP.vue'
import VueApexCharts from 'vue-apexcharts'
import loading from 'vuejs-loading-screen'
import Footer from '@/components/Footer.vue'

Vue.use(loading,{
  bg: '#50a7bc',
  slot: `
      <div class="px-5 py-3 bg-gray-800 rounded">
        <h4 class="text-3xl text-white"><i class="fas fa-spinner fa-spin"></i> preparing download, this can take a few minutes</h4>
      </div>
  `
})

export default {
  name: 'Search',
  components: {
    Footer,
    BodySites,
    Categories,
    Instruments,
    HMP,
    apexchart: VueApexCharts
  },
  data() {
    return {
        bodysites: [],
        categories: [],
        instruments: [],
        hmps: [],
        chartAxis: "bodysite", 
        series: [],
        chartOptions: {
          chart: {
            type: 'pie',
          },
          labels: [],
          responsive: [{
            options: {
              legend: {
                position: 'bottom'
              }
            }
          }]
      }
    }  
  },
  methods: {
    addToFilter(group, item) {
      if (group === 'bodysites') this.bodysites.push(item);
      if (group === 'categories') this.categories.push(item);
      if (group === 'instruments') this.instruments.push(item);
      if (group === 'hmp') this.hmps.push(item);
      this.updateDimensions();
    },
    removeFromFilter(group, item) {
      if (group === 'bodysites') this.bodysites.pop(item);
      if (group === 'categories') this.categories.pop(item);
      if (group === 'instruments') this.instruments.pop(item);
      if (group === 'hmp') this.hmps.pop(item);
      this.updateDimensions();
    },
    updateDimensions() {
      var hmpstr = '';
      if (this.hmps.length == 1) {
        if (this.hmps[0] == 'hmp') {
          hmpstr = 'only';
        } else {
          hmpstr = 'nope';
        }
      }
      axios.post(`${process.env.VUE_APP_FORBIOME_API}/dimensions`, {
        'bodysites': this.bodysites.toString(),
        'categories': this.categories.toString(),
        'instruments': this.instruments.toString(),
        'hmp': hmpstr
      }).then(r => {
        let updates = JSON.parse(r.data);
        this.$refs.bsc.updateDimensions(updates);
        this.$refs.cgc.updateDimensions(updates);
        this.$refs.isc.updateDimensions(updates);
        this.$refs.hmc.updateDimensions(updates);
        this.updateChart(updates);
      }).catch(e => console.error(`could not gather dimensions, ${e}`));
    },
    updateChart(dimensions) {
      this.series = Object.keys(dimensions).filter(k => { return k.includes(this.chartAxis); } ).map(k => { return dimensions[k]});
      this.chartOptions = { ... this.chartOptions, ... {  labels: Object.keys(dimensions).filter(k => { return k.includes(this.chartAxis); } ).map(k => { return k.includes("_") ? k.split("_")[1] : k }) }};
    },
    downloadResults() {
      this.$isLoading(true);
      var hmpstr = '';
      if (this.hmps.length == 1) {
        if (this.hmps[0] == 'hmp') {
          hmpstr = 'only';
        } else {
          hmpstr = 'nope';
        }
      }
      axios.post(`${process.env.VUE_APP_FORBIOME_API}/download_otus`, {
        'bodysites': this.bodysites.toString(),
        'categories': this.categories.toString(),
        'instruments': this.instruments.toString(),
        'hmp': hmpstr
      }, {
          headers: {
              'Content-Disposition': "attachment; filename=result.json",
              'Content-Type': 'application/json'
          },
          responseType: 'blob', 
          timeout: 30000
      }).then(r => {
        const url = window.URL.createObjectURL(new Blob([r.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'result.json');
        document.body.appendChild(link);
        link.click();
      }).catch((e) => console.log(e)).finally(() => {
        this.$isLoading(false);
      })
    },
    downloadTaxas() {
      this.$isLoading(true);
      axios.get(`${process.env.VUE_APP_FORBIOME_API}/download_taxa`, {
          headers: {
              'Content-Disposition': "attachment; filename=taxa.json.gz",
              'Content-Type': 'application/gzip'
          },
          responseType: 'blob', 
          timeout: 30000
      }).then(r => {
        const url = window.URL.createObjectURL(new Blob([r.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'taxa.json.gz');
        document.body.appendChild(link);
        link.click();
      }).catch((e) => console.log(e)).finally(() => {
        this.$isLoading(false);
      })
    },
  },
  mounted() {
    this.updateDimensions();
  }
}
</script>

<style scoped>


.chart-container {
  display: absolute;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 700px;
}



.flow-row {
	display: table-row;
}

.flow-cell {
	display: table-cell;
	padding: 3px 10px;
}

.flow-body {
	display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.flow-download {
  grid-column: 1 / -1;
  text-align: center;
}

.flow-download button {
  display: block;
  padding:15px 30px;
  font-weight: bold;
  width:50%;
  margin:10px auto;
  transition:all ease 300ms;
  text-transform: uppercase;
  font-size: 14px;
}

.flow-download button:hover {
  background-color: var(--bluelight);
  color:var(--blue);
  transition:all ease 300ms;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #fff;
  color: #50a7bc;
  width: 400px;
  padding: 10px 20px;
  font-size: 22px;
  font-family: 'Arial', sans-serif;
}

select option {
  color: #50a7bc;
  padding: 0 10px;
  font-family: 'Arial', sans-serif;
}

button {
  background-color: #50a7bc;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}



@import "../../node_modules/@fortawesome/fontawesome-free/css/all.min.css";
</style>
